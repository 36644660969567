<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  现货资源
-->
<template>
  <!--工程专场-->
  <div class="index-engineering">
    <div class="index-engineering-left">
      <div class="engineering-left-head">
        <div class="engineering-head-left">集采专场</div>
        <div class="engineering-head-right" @click="$router.push({ path: 'bid' })">
          <p>海量需求等你来报价</p>
          <img src="../../../assets/indeximg/menu-right.png" alt="大大买钢" />
        </div>
      </div>

      <div class="index-engineering-left-list" v-if="engineeringList.length > 0">
        <!--一条数据开始-->
        <div
          class="engineering-list-item"
          v-for="(item, index) in engineeringList"
          :key="index"
        >
          <div class="engineering-left-title">
            <p>{{ item.productNames }}</p>
            <p>{{ item.activityName }}</p>
          </div>
          <div class="engineering-left-kg">
            <p>{{ item.weightTotel }}吨</p>
            <p>采购重量</p>
          </div>
          <!-- 专场状态 1-未开始 2-竞价中 3-评标中 4-竞价成功 5-流标 -->
          <div
            class="engineering-left-time"
            v-if="item.activityStatus == 1"
          >
            <count-down
              :startTime="Date.now()"
              :endTime="new Date(item.activateTimeStart).getTime()"
              :dayTxt="'天'"
              :hourTxt="'时'"
              :minutesTxt="'分'"
              :secondsTxt="'秒'"
            >
            </count-down>

            <p class="strat">距离开始</p>
          </div>
          <div
            class="engineering-left-more"
            v-if="item.activityStatus == 1"
            @click="JumpInside(8,item.id,'2')"
          >
            <p>查看详情</p>
          </div>
          <div
            class="engineering-left-time"
            v-if="
              item.activityStatus != 1 && item.activityStatus != 2
            "
          >
            <p class="strat">已结束</p>
          </div>
          <div
           @click="JumpInside(8,item.id,'2')"
            class="engineering-left-more"
            v-if="
              item.activityStatus != 1 && item.activityStatus != 2
            "
          >
            <p>查看详情</p>
          </div>
          <div
            class="engineering-left-time"
            v-if="item.activityStatus == 2"
          >
            <count-down
              :startTime="Date.now()"
              :endTime="new Date(item.activateDelayedEnd).getTime()"
              :dayTxt="'天'"
              :hourTxt="'时'"
              :minutesTxt="'分'"
              :secondsTxt="'秒'"
            >
            </count-down>
            <p class="strat">剩余时间</p>
          </div>
          <div
             @click="JumpInside(8,item.id,'1')"
            class="engineering-left-more bidding"
            v-if="item.activityStatus == 2"
          >
            <p>立即竞价</p>
          </div>
        </div>
        <!--一条数据结束-->
      </div>
    </div>
    <div class="index-engineering-right">
      <div class="engineering-right-head">
        <div class="engineering-head-right">中标公示</div>
      </div>
      <div class="engineering-right-body">
        <div class="engineering-right-title">
          <div class="right-title-name">专场名称</div>
          <div class="right-title-result">结果</div>
          <div class="right-title-time">时间</div>
        </div>
        <div
          class="engineering-right-list"
          v-if="bidActivityWinnerList.length > 0"
        >
          <!--一条数据开始-->
          <div
            class="engineering-right-item"
            v-for="(item,index) in bidActivityWinnerList"
            :key="index"
          >
            <p>{{ item.activityName }}</p>
            <p>{{ item.result }}</p>
            <p>{{ item.appraiseTime }}</p>
          </div>
          <!--一条数据结束-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CountDown from "vue2-countdown";
export default {
  name: "resources",
  components: {
    CountDown,
  },
  data() {
    return {
      zbgsList: [],
      gczcList: [],
    };
  },
  methods: {
    ...mapActions("ddmg/home", ["getBidActivityWinner","getEngineeringList"]),
    goDetails(id){
      this.$router.push({ name: "offerDetails", query: { id: id } });
    },
  },
  computed: {
    ...mapState("ddmg/home", {
      bidActivityWinnerList: (state) => state.bidActivityWinnerList,
      engineeringList: (state) => state.engineeringList,
    }),
  },
  mounted() {
    this.getBidActivityWinner();
    this.getEngineeringList();
  },
  filters: {},
};
</script>
<style>
  i{
    font-style: inherit;
  }
</style>
<style lang="scss" >
.index-engineering {
  width: 1200px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}
.index-engineering .index-engineering-left {
  width: 724px;
  height: 423px;
  background-color: #fff;
}
.index-engineering .index-engineering-right {
  width: 463px;
  height: 423px;
  background-color: #fff;
  caret-color: rgba(0, 0, 0, 0);
}
.index-engineering .index-engineering-left .engineering-left-head {
  height: 72px;
  display: flex;
  align-items: center;
  width: 684px;
  margin: 0 auto;
  justify-content: space-between;
  border-bottom: 1px solid #ddddddff;
  caret-color: rgba(0, 0, 0, 0);
  cursor: default;
}
.index-engineering .index-engineering-left .engineering-head-left {
  font-size: 24px;
  color: #333333ff;
  font-weight: 700;
}

.index-engineering .index-engineering-left .engineering-head-right {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999ff;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.index-engineering .index-engineering-left .engineering-head-right img {
  width: 5.93px;
  height: 5.93px;
  margin-left: 4px;
}
.index-engineering .index-engineering-left-list {
  display: flex;
  flex-direction: column;
  width: 684px;
  margin: 15px auto;
}
.index-engineering .index-engineering-left-list .engineering-list-item {
  display: flex;
  width: 684px;
  align-items: center;
  margin: 30px 0;
}
.index-engineering .index-engineering-left-list .engineering-list-item {
  display: flex;
  width: 684px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.index-engineering .engineering-list-item .engineering-left-title {
  width: 239px;
}

.index-engineering
  .engineering-list-item
  .engineering-left-title
  p:nth-child(1) {
  color: #333333ff;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.index-engineering
  .engineering-list-item
  .engineering-left-title
  p:nth-child(2) {
  color: #999999ff;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  margin-top: 8px;
}
.index-engineering .engineering-list-item .engineering-left-kg {
  width: 118.5px;
  text-align: center;
}

.index-engineering .engineering-list-item .engineering-left-kg p:nth-child(1) {
  color: #3d7dffff;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.index-engineering .engineering-list-item .engineering-left-kg p:nth-child(2) {
  color: #999999ff;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  margin-top: 8px;
}
.index-engineering .engineering-list-item .engineering-left-time {
  width: 191.5px;
  text-align: center;
  color: #333333ff;
}

.index-engineering .engineering-list-item .engineering-left-time .count-dow {
  color: #fd6b30ff;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.index-engineering .engineering-list-item .engineering-left-time .strat {
  color: #999999ff;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  margin-top: 8px;
}
.index-engineering .engineering-list-item .engineering-left-more {
  width: 135px;
  text-align: center;
}
.index-engineering .engineering-list-item .engineering-left-more p {
  width: 90px;
  height: 32px;
  border: 1px solid #ef2147ff;
  line-height: 32px;
  color: #ef2147ff;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
}
.index-engineering .engineering-list-item .engineering-left-more.bidding p {
  background-color: #ef2147ff;
  color: #fff;
}
.index-engineering .engineering-right-head {
  height: 72px;
  display: flex;
  align-items: center;
  width: 423px;
  margin: 0 auto;
  justify-content: space-between;
  border-bottom: 1px solid #ddddddff;
}

.index-engineering .engineering-right-head .engineering-head-right {
  font-size: 24px;
  color: #333333ff;
  font-weight: 700;
}

.index-engineering .engineering-right-body .engineering-right-title {
  display: flex;
  height: 52px;
  line-height: 52px;
  margin: 0px auto;
  border-bottom: 1px solid #f1f1f1ff;
  color: #999999ff;
  font-size: 12px;
}
.index-engineering .engineering-right-title .right-title-name {
  margin-left: 20px;
  width: 152px;
}
.index-engineering .engineering-right-title .right-title-result {
  width: 149px;
}
.index-engineering .engineering-right-title .right-title-time {
  width: 142px;
}

.index-engineering .engineering-right-item {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333ff;
  border-bottom: 1px solid #f1f1f1ff;
  height: 56px;
  align-items: center;
}
.engineering-right-list .engineering-right-item:last-child {
  border-bottom: none;
}
.index-engineering .engineering-right-item p{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding-right:15px;
}
.index-engineering .engineering-right-item p:nth-child(1) {
  margin-left: 20px;
  width: 152px;
}

.index-engineering .engineering-right-item p:nth-child(2) {
  width: 149px;
}

.index-engineering .engineering-right-item p:nth-child(3) {
  width: 142px;
}
</style>
