<template>
  <!--现货资源-->
  <div class="index-resources">
    <div class="resources-nav">
      <div class="resources-title">
        <img src="../../../assets/indeximg/3485.svg" alt="大大买钢" />
        <p>现货资源</p>
      </div>
      <div class="resources-tab">
        <div
          :class="{
            'resources-item': true,
            active: resourcesTab == index,
          }"
          v-for="(item, index) in resourcesList"
          :key="index"
          @click="getPM(item, index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="resources-mark pointer">
        <p @click="JumpInside(6)">最新、最全现货一网打尽</p>
        <img src="../../../assets/indeximg/menu-right.png" alt="大大买钢" />
      </div>
    </div>

    <div class="resources-list">
      <!--一条数据开始-->
      <div class="resources-list-item" v-for="(item,index) in homeMallList" :key="index" @click="JumpInside(7,item.id)">
        <div class="resources-item-img">
          <img :src="item.imageUrl" alt="大大买钢" />
        </div>
        <div class="resources-item-body">
          <div class="item-body-title">
            <p>{{item.productName}} {{item.specifications}} {{item.material}} {{item.origin_place}}</p>
          </div>
          <div class="item-body-content today" v-if="item.price!='0.00'&&item.price!=''">
            <p>今日价格</p>
            <div class="item-body-price">
              <img src="../../../assets/indeximg/4220.svg" alt="大大买钢" />
              <p>¥{{item.price}}</p>
            </div>
          </div>

          <div class="item-body-content face" v-else>
            <p>电议</p>
            <div class="item-body-price"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "resources",
  data() {
    return {
      resourcesTab: 0,
      resourcesList: [],
    };
  },
  created() {
    this.getHomeMallData().then(res=>{
        this.resourcesList = res.resultdata
        this.getHomeMallDataIndex(this.resourcesList[0]);
    })

  },
  methods: {
    ...mapActions("ddmg/home", ["getHomeMallDataIndex","getHomeMallData"]),
    // goProduct(item){
    //   console.log(item.id)
    // },
    getPM(item, index) {
      this.getHomeMallDataIndex(item);
      this.resourcesTab = index;
    },
  },
  computed: {
    ...mapState("ddmg/home", {
      homeMallList: (state) => state.homeMallList,
    }),
  },
};
</script>

<style lang="scss" >
.index-resources {
  width: 1200px;
  margin: 10px auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.index-resources .resources-nav {
  display: flex;
  width: 1148px;
  height: 73px;
  border-bottom: 1px solid #e9e9e9ff;
  align-items: center;
  margin: 0 26px;
  box-sizing: border-box;
}

.index-resources .resources-nav .resources-title {
  display: flex;
  align-items: center;
  margin-right: 30px;
  width: 188px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: default;
}

.index-resources .resources-nav .resources-title img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.index-resources .resources-nav .resources-title p {
  font-size: 24px;
  font-weight: 700;
  color: #333333ff;
}

.index-resources .resources-nav .resources-tab {
  display: flex;
  width: 807.07px;
}

.index-resources .resources-nav .resources-tab .resources-item {
  margin-left: 30px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 400;
  height: 73px;
  display: flex;
  align-items: center;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}
.index-resources .resources-nav .resources-tab .resources-item.active {
  color: #ef2147ff;
  border-bottom: 1px solid #ef2147ff;
}

.index-resources .resources-nav .resources-mark {
  width: 190.93px;
  display: flex;
  font-size: 14px;
  color: #999999ff;
  align-items: center;
  justify-content: flex-end;
  caret-color: rgba(0, 0, 0, 0);
}

.index-resources .resources-nav .resources-mark img {
  width: 5.96px;
  height: 5.96px;
  margin-left: 4px;
}

.index-resources .resources-list {
  width: 1148px;
  height: 286px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 26px;
}

.index-resources .resources-list .resources-list-item {
  width: 341px;
  height: 98px;
  display: flex;
  margin-top: 30px;
}

.index-resources .resources-list .resources-list-item .resources-item-img {
  margin-right: 10px;
  width: 98px;
}
.index-resources .resources-list .resources-list-item .resources-item-img img {
  width: 100%;
}
.index-resources .resources-list .resources-list-item .resources-item-body {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #ef2147ff;
  font-weight: 400;
  text-align: center;
}

.index-resources
  .resources-list
  .resources-list-item
  .item-body-content.today
  .item-body-price {
  width: 100%;
  /*! height: 38.49px; */
  background: url(../../../assets/indeximg/3228.svg) no-repeat center;
  background-size: 100%;
  background-position-x: -9px;
}

.index-resources
  .resources-list
  .resources-list-item
  .item-body-content.today
  .item-body-price
  p:nth-child(2) {
  width: 58px;
  height: 18px;
  background-color: #ef2147ff;
  border-radius: 5px;
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
}

.index-resources
  .resources-list
  .resources-list-item
  .item-body-content.face
  .item-body-price {
  width: 100%;
  height: 38.49px;
  background: url(../../../assets/indeximg/3229.svg) no-repeat center;
  background-size: 100%;
}

.index-resources
  .resources-list
  .resources-list-item
  .item-body-content.face
  p:nth-child(1) {
  width: 50px;
  height: 20px;
  border: 1px solid #ef2147ff;
  border-radius: 5px;
  line-height: 20px;
  margin: 0 auto;
}

.index-resources .resources-list .resources-list-item .item-body-content {
  width: 100%;
  margin-top: 17px;
  text-align: center;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.index-resources .resources-list .resources-list-item .item-body-content img {
  width: 7.96px;
}

.index-resources .resources-list .resources-list-item .item-body-title {
  font-size: 16px;
  color: #333333ff;
  font-weight: 700;
  height: 22px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 233px;
}
.index-resources .resources-list .resources-list-item .item-body-title p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
</style>
