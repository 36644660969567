var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"platcoopeer"},[_c('div',{staticClass:"platform"},[_vm._m(0),_c('div',{staticClass:"platform-body"},[_vm._l((_vm.platformList),function(item,index){return _c('div',{key:index,class:{ 'platform-body-tiem': true },on:{"mouseenter":function($event){return _vm.onHover(index)},"mouseleave":function($event){return _vm.onLeave(index)}}},[_c('div',{class:{
            'platform-body-bg': true,
            'img-actives': item.platformListItem == index + 1,
          },staticStyle:{"height":"182px","width":"274px","background-repeat":"no-repeat","background-size":"100% 100%"},style:({
            'background-image':
              'url(' +
              require('../../../assets/indeximg/Industrychain_' +
                (index + 1) +
                '.png') +
              ')',
          })}),_c('div',{class:{
            'platform-body-item-img': true,
            'img-active': item.platformListItem == index + 1,
          }},[_c('img',{attrs:{"src":require('../../../assets/indeximg/financial' +
                (index + 1) +
                '.png'),"alt":"大大买钢"}})]),(item.platformListItem == index + 1 && item.gotoHref)?_c('div',{staticClass:"platform-body-item-button",on:{"click":function($event){return _vm.gotonewPage(item.gotoHref)}}},[_c('p',[_vm._v("查看详情")])]):_vm._e()])}),_vm._m(1)],2)]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"platform-header"},[_c('div',{staticClass:"platform-title"},[_c('img',{attrs:{"src":require("../../../assets/indeximg/3120.svg"),"alt":"大大买钢"}}),_c('p',[_vm._v("平台服务")])]),_c('div',{staticClass:"platform-desc"},[_vm._v(" 利用云计算、大数据、微服务等互联网技术，整合钢铁产业链流通环节资源，为平台用户提供系列全数字化交易、金融、物流、资讯产品与服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"platform-body-tiem platform-body-tiem-sd"},[_c('div',{staticClass:"platform-body-item-img platform-body-item-img-hover"},[_c('img',{attrs:{"src":require("../../../assets/indeximg/financial4.png"),"alt":"大大买钢"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cooperation"},[_c('div',{staticClass:"platform-header"},[_c('div',{staticClass:"platform-title"},[_c('img',{attrs:{"src":require("../../../assets/indeximg/3119.svg"),"alt":"大大买钢"}}),_c('p',[_vm._v("合作伙伴")])]),_c('div',{staticClass:"platform-desc"},[_vm._v("我们的品牌商。")])]),_c('div',{staticClass:"cooperation-body"},[_c('div',[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/01.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/02.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/03.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/04.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/05.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/06.png"),"alt":"大大买钢"}})])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/07.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/08.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/09.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/10.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/11.png"),"alt":"大大买钢"}})]),_c('li',[_c('img',{attrs:{"src":require("../../../assets/indeximg/hzhb/12.png"),"alt":"大大买钢"}})])])]),_c('div')])])
}]

export { render, staticRenderFns }