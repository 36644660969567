<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  echarts表格
-->
<template>
  <div class="index-two-echarts">
    <div class="echarts-left">
      <div class="echarts-title">实时数据</div>
      <div class="echarts-content">
        <div class="echarts-content-item">
          <span>今日成交量（吨）</span><br />
          <span style="color: #ff6200; font-size: 15px"
            >{{ echartsData.thisDayWeight }}
          </span>
        </div>
        <div class="echarts-content-item">
          <span>昨日成交量（吨）</span><br />
          <span style="color: #3d7dffff; font-size: 15px"
            >{{ echartsData.last1dayWeight }}
          </span>
        </div>
        <div class="echarts-content-item">
          <span>前日成交量（吨）</span><br />
          <span style="color: #ffc447; font-size: 15px"
            >{{ echartsData.last2dayWeight }}
          </span>
        </div>
      </div>
      <div
        id="myChart"
        :style="{ width: '95%', height: '318px', 'text-align': 'center' }"
      ></div>
    </div>
    <div class="echarts-right">
      <div class="echarts-title">成交量走势</div>
      <div class="echarts-content">
        <div class="echarts-content-item">
          <span >本周累计（吨）</span><br />
          <span style="color: #ff6200; font-size: 15px">{{ echartsData.thisWeekWeight }}</span>
        </div>
        <div class="echarts-content-item">
          <span>上周累计（吨）</span><br />
          <span style="color: #3d7dffff; font-size: 15px">{{ echartsData.last1weekWeight }}</span>
        </div>
        <div class="echarts-content-item">
          <span>上上周累计（吨）</span><br />
          <span style="color: #ffc447; font-size: 15px">{{ echartsData.last2weekWeight }}</span>
        </div>
      </div>
      <div
        id="myChart2"
        :style="{ width: '95%', height: '318px', 'text-align': 'center' }"
      ></div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import dayjs from "dayjs";
import { mapActions } from "vuex";
export default {
  name: "echartsexcel",
  data() {
    return {
      echartsData: {
        real_time_x_axis: ["9:00", "11:00", "13:00", "15:00", "17:00", "19:00", "23:00"],
        week_real_time_x_axis: ["周一", "周二", "周三", "周四", "周五", "周六", "周日",],
        real_time_list: [
          {
            name: "昨日",
            data: [],
          },
          {
            name: "今日",
            data: [],
          },
          {
            name: "前日",
            data: [],
          },
        ],
        week_real_time_list: [
          {
            name: "上周",
            data: [],
          },
          {
            name: "本周",
            data: [],
          },
          {
            name: "上上周",
            data: [],
          },
        ],
        last1dayWeight: "",
        thisDayWeight: "",
        last2dayWeight: "",
        thisWeekWeight: "",
        last1weekWeight: "",
        last2weekWeight: "",
      },
    };
  },
  methods: {
    ...mapActions("ddmg/home", ["getRealTimeData","getTransactionData"]),
    drawLine() {
      let myChart = echarts.init(document.getElementById("myChart"));
      let option = {
        title: {
          textStyle: {
            fontSize: 14,
            color: "#666666",
            fontWeight: "400",
          },
          left: "30",
          top: "20",
          text: "下单量（千吨）",
          x: "10px", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: "10px", //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "white",
          borderColor: "#DDDDDD", // 提示框浮层的边框颜色。
          borderWidth: 1, // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#666666",
            fontSize: 14,
            align: "left",
          },
          extraCssText: "box-shadow:0px 0px 5px rgba(0, 0, 0, 0.19)", // 悬浮框阴影
          formatter: function (val) {
            console.log("##formatter00=>",val);
            let i =
              '<span style="border-radius:10px;display:inline-block;margin-right:4px;width:5px;height:5px;background-color:white;border:3px solid #FF6200"></span>';
            let i2 =
              '<span style="border-radius:10px;display:inline-block;margin-right:4px;width:5px;height:5px;background-color:white;border:3px solid #3D7DFF"></span>';
            let i3 =
              '<span style="border-radius:10px;display:inline-block;margin-right:4px;width:5px;height:5px;background-color:white;border:3px solid #FFC447"></span>';
            return (
              i +
              val[0].seriesName +
              " " +
              val[0].value +
              "吨" +
              "<br>" +
              i2 +
              val[1].seriesName +
              " " +
              val[1].value +
              "吨" +
              "<br>" + (val[2] ? (i3 + val[2].seriesName + val[2].value + "吨") : '')
              // i3 +
              // val[2].seriesName +
              // " " +
              // val[2].value +
              // "吨"
            );
            // do some thing
          },
        },
        legend: {
          // orient: 'vertical',
          // icon:'pin',
          itemWidth: 12,
          itemHeight: 12,
          x: "right",
          y: "20px",
          data: ["今日", "昨日", "前日"],
        },
        toolbox: {},
        grid: {
          left: "6%",
          right: "6%",
          bottom: "6%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          type: "category",
          boundaryGap: true,
          data: this.echartsData.real_time_x_axis,
        },
        yAxis: {
          type: "value", //坐标轴类型
          positionL: "left",
          //y轴
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
            },
            formatter: function (value, index) {
              if (value == 0) {
                return 0;
              } else {
                return (value / 1000).toFixed(0);
              }
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        series: [
          {
            name: this.echartsData.real_time_list[1].name,
            type: "line",
            data: this.echartsData.real_time_list[1].data,
            itemStyle: {
              normal: {
                color: "#FF6200", //图标颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#FF6200", //连线颜色
              },
            },
          },
          {
            name: this.echartsData.real_time_list[0].name,
            type: "line",
            data: this.echartsData.real_time_list[0].data,
            itemStyle: {
              normal: {
                color: "#3D7DFF", //图标颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#3D7DFF", //连线颜色
              },
            },
          },
          {
            name: this.echartsData.real_time_list[2].name,
            type: "line",
            data: this.echartsData.real_time_list[2].data,
            itemStyle: {
              normal: {
                color: "#FFC447", //图标颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#FFC447", //连线颜色
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    drawColumnar() {
        let myChart = echarts.init(document.getElementById("myChart2"));
        let option = {
            title: {
                textStyle: {
                    fontSize: 14,
                    color: "#666666",
                    fontWeight: "400",
                },
                left: "30",
                top: "20",
                text: "成交量（万吨）",
                x: "10px", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
                y: "10px", //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
            },
            tooltip: {
                trigger: "axis",
                backgroundColor: "white",
                borderColor: "#DDDDDD", // 提示框浮层的边框颜色。
                borderWidth: 1, // 提示框浮层的边框宽。
                textStyle: {
                    // 提示框浮层的文本样式。
                    color: "#666666",
                    fontSize: 14,
                    align: "left",
                },
                extraCssText: "box-shadow:0px 0px 5px rgba(0, 0, 0, 0.19)", // 悬浮框阴影
                formatter: function (val) {
                    let i =
                        '<span style="display:inline-block;margin-bottom:3px;margin-right:4px;width:11px;height:3px;background-color:#FF6200;"></span>';
                    let i2 =
                        '<span style="display:inline-block;margin-bottom:3px;margin-right:4px;width:11px;height:3px;background-color:#3D7DFF;"></span>';
                    let i3 =
                        '<span style="display:inline-block;margin-bottom:3px;margin-right:4px;width:11px;height:3px;background-color:#FFC447;"></span>';
                    return (
                        i +
                        "本周" +
                        " " +
                        val[0].value +
                        "吨" +
                        "<br>" +
                        i2 +
                        "上周" +
                        " " +
                        val[1].value +
                        "吨"+
                        "<br>" +
                        i3 +
                        "上上周" +
                        " " +
                        val[2].value +
                        "吨"
                    );
                    // do some thing
                },
            },
            legend: {
                // orient: 'vertical',
                // icon:'pin',
                itemWidth: 11,
                itemHeight: 3,
                x: "right",
                y: "20px",
                data: ["本周", "上周", "上上周"],
                itemStyle: {
                    borderWidth: "30px",
                },
            },
            toolbox: {},
            grid: {
                left: "6%",
                right: "6%",
                bottom: "6%",
                containLabel: true,
            },
            xAxis: {
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#666666",
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#DDDDDD",
                    },
                },
                type: "category",
                boundaryGap: true,
                data: this.echartsData.week_real_time_x_axis,
            },
            yAxis: {
                type: "value", //坐标轴类型
                positionL: "left",
                //y轴
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#666666",
                    },
                    formatter: function (value, index) {
                        if (value == 0) {
                            return 0;
                        } else {
                            return (value / 10000).toFixed(0);
                        }
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#DDDDDD",
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dashed",
                    },
                },
            },

            series: [

                {
                    name: this.echartsData.week_real_time_list[1].name,
                    type: "bar",
                    barGap: "0%",
                    barWidth: "10",
                    barCategoryGap: "0%",
                    data: this.echartsData.week_real_time_list[1].data,
                    itemStyle: {
                        normal: {
                            color: "#FF6200", //图标颜色
                        },
                    },
                    lineStyle: {
                        normal: {
                            color: "#FF6200", //连线颜色
                        },
                    },
                },
                {
                    name: this.echartsData.week_real_time_list[0].name,
                    type: "bar",
                    barGap: "0%",
                    barWidth: "10",
                    barCategoryGap: "0%",
                    data: this.echartsData.week_real_time_list[0].data,
                    itemStyle: {
                        normal: {
                            color: "#3D7DFF", //图标颜色
                        },
                    },
                    lineStyle: {
                        normal: {
                            color: "#3D7DFF", //连线颜色
                        },
                    },
                },
                {
                    name: this.echartsData.week_real_time_list[2].name,
                    type: "bar",
                    barGap: "0%",
                    barWidth: "10",
                    barCategoryGap: "0%",
                    data: this.echartsData.week_real_time_list[2].data,
                    itemStyle: {
                        normal: {
                            color: "#FFC447", //图标颜色
                        },
                    },
                    lineStyle: {
                        normal: {
                            color: "#FFC447", //连线颜色
                        },
                    },
                },
            ],
        };
        option && myChart.setOption(option);
    },
  },
  computed: {},
  mounted() {
    this.getRealTimeData().then((res) => {
        if(res.resultdata){
            this.echartsData.real_time_list[0].data = [
                res.resultdata.last1day9Weight * 2,
                res.resultdata.last1day11Weight * 2,
                res.resultdata.last1day13Weight * 2,
                res.resultdata.last1day15Weight * 2,
                res.resultdata.last1day17Weight * 2,
                res.resultdata.last1day19Weight * 2,
                res.resultdata.last1day23Weight * 2,
            ];
            this.echartsData.real_time_list[1].data = [
                res.resultdata.thisDay9Weight * 2,
                // res.resultdata.thisDay11Weight * 2,
                // res.resultdata.thisDay13Weight * 2,
                // res.resultdata.thisDay15Weight * 2,
                // res.resultdata.thisDay17Weight * 2,
                // res.resultdata.thisDay19Weight * 2,
            ];
            this.echartsData.real_time_list[2].data = [
                res.resultdata.last2day9Weight * 2,
                res.resultdata.last2day11Weight * 2,
                res.resultdata.last2day13Weight * 2,
                res.resultdata.last2day15Weight * 2,
                res.resultdata.last2day17Weight * 2,
                res.resultdata.last2day19Weight * 2,
                res.resultdata.last2day23Weight * 2,
            ];
            if (dayjs().hour() >= 9) {
              this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay11Weight * 2);
              if (dayjs().hour() >= 11) {
                this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay13Weight * 2);
              }
              if (dayjs().hour() >= 13) {
                this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay15Weight * 2);
              } 
              if (dayjs().hour() >= 15) {
                this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay17Weight * 2);
              } 
              if (dayjs().hour() >= 17) {
                this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay19Weight * 2);
              }
              if (dayjs().hour() >= 19) {
                this.echartsData.real_time_list[1].data.push(res.resultdata.thisDay23Weight * 2);
              }
            }
            this.echartsData.last1dayWeight = (res.resultdata.last1dayWeight * 2).toFixed(2).toLocaleString();
            this.echartsData.thisDayWeight = (res.resultdata.thisDayWeight * 2).toFixed(2).toLocaleString();
            this.echartsData.last2dayWeight = (res.resultdata.last2dayWeight * 2).toFixed(2).toLocaleString();
            this.drawLine();
        }
    });
    this.getTransactionData().then((res) => {
        if(res.resultdata){
        this.echartsData.week_real_time_list[0].data = [
            res.resultdata.last1week1Weight * 2,
            res.resultdata.last1week2Weight * 2,
            res.resultdata.last1week3Weight * 2,
            res.resultdata.last1week4Weight * 2,
            res.resultdata.last1week5Weight * 2,
            res.resultdata.last1week6Weight * 2,
            res.resultdata.last1week7Weight * 2,
        ];
        this.echartsData.week_real_time_list[1].data = [
            res.resultdata.thisWeek1Weight * 2,
            res.resultdata.thisWeek2Weight * 2,
            res.resultdata.thisWeek3Weight * 2,
            res.resultdata.thisWeek4Weight * 2,
            res.resultdata.thisWeek5Weight * 2,
            res.resultdata.thisWeek6Weight * 2,
            res.resultdata.thisWeek7Weight * 2,
        ];
        this.echartsData.week_real_time_list[2].data = [
            res.resultdata.last2week1Weight * 2,
            res.resultdata.last2week2Weight * 2,
            res.resultdata.last2week3Weight * 2,
            res.resultdata.last2week4Weight * 2,
            res.resultdata.last2week5Weight * 2,
            res.resultdata.last2week6Weight * 2,
            res.resultdata.last2week7Weight * 2,
        ];
        this.echartsData.thisWeekWeight = (res.resultdata.thisWeekWeight * 2).toFixed(2).toLocaleString();
        this.echartsData.last1weekWeight = (res.resultdata.last1weekWeight * 2).toFixed(2).toLocaleString();
        this.echartsData.last2weekWeight = (res.resultdata.last2weekWeight * 2).toFixed(2).toLocaleString();
        this.drawColumnar();
        }
    });
  },
};
</script>

<style lang="scss" >
.index-two-echarts {
  max-width: 1200px;
  display: flex;
  margin: 0px auto;
  justify-content: space-between;
  height: 482px;
}
.index-two-echarts .echarts-left {
  width: 595px;
  background-color: #ffffff;
}
.index-two-echarts .echarts-right {
  width: 592px;
  background-color: #ffffff;
}
.index-two-echarts .echarts-title {
  font-family: PingFang SC;
  font-weight: 700;
  color: #333333;
  font-size: 24px;
  line-height: 28px;
  padding: 24px 0 28px 20px;
}
.echarts-content {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.echarts-content-item span:nth-child(1) {
  font-family: PingFang SC;
  color: #666666;
  font-size: 14px;
}

.echarts-content-item span:nth-child(3) {
  font-family: PingFang SC;
  font-weight: 700;
  color: #fd6b30;
  font-size: 16px;
}
</style>
