<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  推荐商铺
-->
<template>
  <!--推荐商铺-->
  <div
    class="index-recommend"
    v-loading="companyLoading"
  >
    <div class="recommend-nav">
      <div class="recommend-title">
        <img
          src="../../../assets/indeximg/3068.svg"
          alt="大大买钢"
        />
        <p>推荐商铺</p>
      </div>
      <div class="recommend-tab">
        <div
          :class="{
            'recommend-item': true,
            active: recommendTab == index,
          }"
          v-for="(item, index) in regionList"
          :key="index"
          @click="tabRegion(index, item)"
        >
          {{ item }}
        </div>
      </div>
      <div
        class="recommend-mark"
        @click="JumpInside(1)"
      >
        <p>品质商铺，尽在其中</p>
        <img
          src="../../../assets/indeximg/menu-right.png"
          alt="大大买钢"
        />
      </div>
    </div>

    <div
      class="index-recommend-list"
      v-if="companyList.length >0"
    >
      <!--一条数据开始-->
      <div
        class="recommend-list-item item-border"
        v-for="(item, index) in companyList"
        :key="index"
      >
        <div :class="
            item.isInternalCompany != 1
              ? 'recommend-item-title item-title-blue'
              : 'recommend-item-title item-title-yellow'
          ">
          <div class="item-title-img"></div>
          <div
            class="recommend-item-desc"
            @click="JumpInside(3, item.companyId)"
          >
            <div class="recommend-desc-name">
              <p>{{ item.companyName }}</p>
            </div>
            <div class="recommend-desc-situation">
              <p>近3月 成交{{ item.lastThreeMonthTrading }}万吨</p>
            </div>
            <div class="recommend-desc-label">
              <p v-if="item.isInternalCompany == 1">直营店铺</p>
              <p>一票制/多票制</p>
            </div>
          </div>
        </div>
        <div class="recommend-item-body">
          <div
            class="recommend-item-strip"
            v-for="(xitem, xindex) in item.goods"
            :key="xindex"
            @click="JumpInside(2,xitem.id)"
          >
            <div class="recommend-strip-left">
              <div class="strip-left-title">
                <p>{{ xitem.originPlace }} {{ xitem.productName }}</p>
              </div>
              <div class="strip-left-desc">
                <p>{{ xitem.material }} {{ xitem.specifications }}</p>
              </div>
            </div>
            <div class="recommend-strip-right">
              <div class="strip-left-price">
                <p>￥{{ xitem.price }}</p>
              </div>
              <div class="strip-left-date">
                <p>{{ xitem.createTime }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="price-guidance-divImg"
      v-if="companyList.length == 0"
    >
      <img
        src="../img/nodata.gif"
        style="width: 200px; height: 200px"
        alt="大大买钢"
      />
      <div style="margin-top: 30px; font-size: 16px; color: #999999">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "recommend",
  data () {
    return {
      recommendTab: 0,

    };
  },
  methods: {
    ...mapActions("ddmg/home", ["getCompany", "getRegionAndProduct"]),
    tabRegion (index, item) {
      this.recommendTab = index;
      this.getCompany(item);
    },
  },
  mounted () {
    this.getRegionAndProduct().then((res) => {
      if (res.msgcode == 1) {
        let region = res.resultdata.areaList[0];
        console.log("region", region);
        this.getCompany(region);
      }
    });
  },
  computed: {
    ...mapState("ddmg/home", {
      companyList: (state) => state.companyList,
      regionList: (state) => state.regionList,
      companyLoading: (state) => state.companyLoading,
    }),
  },
};
</script>

<style lang="scss" >
.price-guidance-divImg {
  padding-bottom: 10px;
  height: 284px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.index-recommend {
  width: 1200px;
  background-color: #fff;
  margin: 10px auto;
}
.index-recommend .recommend-nav {
  display: flex;
  width: 1148px;
  height: 73px;
  border-bottom: 1px solid #e9e9e9ff;
  align-items: center;
  margin: 0 26px;
  box-sizing: border-box;
}
.index-recommend .recommend-nav .recommend-title {
  display: flex;
  align-items: center;
  margin-right: 30px;
  width: 188px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: default;
}
.index-recommend .recommend-nav .recommend-title img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.index-recommend .recommend-nav .recommend-title p {
  font-size: 24px;
  font-weight: 700;
  color: #333333ff;
}
.index-recommend .recommend-nav .recommend-tab {
  display: flex;
  width: 807.07px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.index-recommend .recommend-nav .recommend-tab .recommend-item {
  margin-left: 30px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 400;
  height: 73px;
  display: flex;
  align-items: center;
}
.index-recommend .recommend-nav .recommend-tab .recommend-item.active {
  color: #ef2147ff;
  border-bottom: 1px solid #ef2147ff;
}

.index-recommend .recommend-nav .recommend-mark {
  width: 190.93px;
  display: flex;
  font-size: 14px;
  color: #999999ff;
  align-items: center;
  justify-content: flex-end;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.index-recommend .recommend-nav .recommend-mark img {
  width: 5.96px;
  height: 5.96px;
  margin-left: 4px;
}
.index-recommend .index-recommend-list {
  width: 1200px;
  height: 284px;
  display: flex;
  justify-content: space-around;
  margin-left: 20px;
}
.index-recommend .index-recommend-list .recommend-list-item {
  width: 349.5px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.index-recommend
  .index-recommend-list
  .recommend-list-item
  .recommend-item-title {
  display: flex;
  align-items: center;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title
  .item-title-img {
  width: 50px;
  height: 50px;
  margin-right: 12px;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-blue
  .item-title-img {
  background: url(../../../assets/indeximg/3422.svg) no-repeat;
  background-size: 100%;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-yellow
  .item-title-img {
  background: url(../../../assets/indeximg/3423.svg) no-repeat;
  background-size: 100%;
}

.index-recommend-list
  .recommend-list-item
  .recommend-item-title
  .recommend-desc-name {
  font-size: 16px;
  color: #333333ff;
  font-weight: 700;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title
  .recommend-desc-situation {
  font-size: 13px;
  color: #999999ff;
  font-weight: 400;
  margin-top: 4px;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-blue
  .recommend-desc-label {
  font-size: 12px;
  color: #3d7dffff;
  font-weight: 400;
  margin-top: 4px;
  border: 1px solid #3d7dffff;
  width: 87px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  border-radius: 4px;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-yellow
  .recommend-desc-label {
  display: flex;
  align-items: center;
}
.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-yellow
  .recommend-desc-label
  p:nth-child(1) {
  font-size: 12px;
  color: #fc9248ff;
  font-weight: 400;
  margin-top: 4px;
  border: 1px solid #fc9248ff;
  width: 85px;
  height: 21px;
  line-height: 22px;
  text-align: center;
  border-radius: 4px;
  margin-right: 4px;
}

.index-recommend-list
  .recommend-list-item
  .recommend-item-title.item-title-yellow
  .recommend-desc-label
  p:nth-child(2) {
  font-size: 12px;
  color: #3d7dffff;
  font-weight: 400;
  margin-top: 4px;
  border: 1px solid #3d7dffff;
  width: 87px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  border-radius: 4px;
}

.index-recommend-list .recommend-item-body {
  margin-top: 15px;
}
.index-recommend-list .item-border {
  border-right: 1px solid #e8e8e8ff;
  box-sizing: border-box;
  height: 80%;
}
.index-recommend-list .item-border:last-child {
  border-right: none;
}
.index-recommend-list .recommend-item-strip {
  display: flex;
  margin-top: 21px;
}
.index-recommend-list .recommend-item-strip .recommend-strip-left {
  width: 172.5px;
}
.index-recommend-list .recommend-item-strip .recommend-strip-right {
  width: 110.5px;
  text-align: center;
}

.index-recommend-list .recommend-item-strip .strip-left-title {
  color: #333333ff;
  font-size: 16px;
  font-weight: 400;
}

.index-recommend-list .recommend-item-strip .strip-left-desc {
  color: #666666ff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 9px;
}

.index-recommend-list .recommend-item-strip .strip-left-price {
  color: #333333ff;
  font-size: 17px;
  font-weight: 700;
}

.index-recommend-list .recommend-item-strip .strip-left-date {
  color: #666666ff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 9px;
}
</style>
