var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-engineering"},[_c('div',{staticClass:"index-engineering-left"},[_c('div',{staticClass:"engineering-left-head"},[_c('div',{staticClass:"engineering-head-left"},[_vm._v("集采专场")]),_c('div',{staticClass:"engineering-head-right",on:{"click":function($event){return _vm.$router.push({ path: 'bid' })}}},[_c('p',[_vm._v("海量需求等你来报价")]),_c('img',{attrs:{"src":require("../../../assets/indeximg/menu-right.png"),"alt":"大大买钢"}})])]),(_vm.engineeringList.length > 0)?_c('div',{staticClass:"index-engineering-left-list"},_vm._l((_vm.engineeringList),function(item,index){return _c('div',{key:index,staticClass:"engineering-list-item"},[_c('div',{staticClass:"engineering-left-title"},[_c('p',[_vm._v(_vm._s(item.productNames))]),_c('p',[_vm._v(_vm._s(item.activityName))])]),_c('div',{staticClass:"engineering-left-kg"},[_c('p',[_vm._v(_vm._s(item.weightTotel)+"吨")]),_c('p',[_vm._v("采购重量")])]),(item.activityStatus == 1)?_c('div',{staticClass:"engineering-left-time"},[_c('count-down',{attrs:{"startTime":Date.now(),"endTime":new Date(item.activateTimeStart).getTime(),"dayTxt":'天',"hourTxt":'时',"minutesTxt":'分',"secondsTxt":'秒'}}),_c('p',{staticClass:"strat"},[_vm._v("距离开始")])],1):_vm._e(),(item.activityStatus == 1)?_c('div',{staticClass:"engineering-left-more",on:{"click":function($event){return _vm.JumpInside(8,item.id,'2')}}},[_c('p',[_vm._v("查看详情")])]):_vm._e(),(
            item.activityStatus != 1 && item.activityStatus != 2
          )?_c('div',{staticClass:"engineering-left-time"},[_c('p',{staticClass:"strat"},[_vm._v("已结束")])]):_vm._e(),(
            item.activityStatus != 1 && item.activityStatus != 2
          )?_c('div',{staticClass:"engineering-left-more",on:{"click":function($event){return _vm.JumpInside(8,item.id,'2')}}},[_c('p',[_vm._v("查看详情")])]):_vm._e(),(item.activityStatus == 2)?_c('div',{staticClass:"engineering-left-time"},[_c('count-down',{attrs:{"startTime":Date.now(),"endTime":new Date(item.activateDelayedEnd).getTime(),"dayTxt":'天',"hourTxt":'时',"minutesTxt":'分',"secondsTxt":'秒'}}),_c('p',{staticClass:"strat"},[_vm._v("剩余时间")])],1):_vm._e(),(item.activityStatus == 2)?_c('div',{staticClass:"engineering-left-more bidding",on:{"click":function($event){return _vm.JumpInside(8,item.id,'1')}}},[_c('p',[_vm._v("立即竞价")])]):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"index-engineering-right"},[_vm._m(0),_c('div',{staticClass:"engineering-right-body"},[_vm._m(1),(_vm.bidActivityWinnerList.length > 0)?_c('div',{staticClass:"engineering-right-list"},_vm._l((_vm.bidActivityWinnerList),function(item,index){return _c('div',{key:index,staticClass:"engineering-right-item"},[_c('p',[_vm._v(_vm._s(item.activityName))]),_c('p',[_vm._v(_vm._s(item.result))]),_c('p',[_vm._v(_vm._s(item.appraiseTime))])])}),0):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engineering-right-head"},[_c('div',{staticClass:"engineering-head-right"},[_vm._v("中标公示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engineering-right-title"},[_c('div',{staticClass:"right-title-name"},[_vm._v("专场名称")]),_c('div',{staticClass:"right-title-result"},[_vm._v("结果")]),_c('div',{staticClass:"right-title-time"},[_vm._v("时间")])])
}]

export { render, staticRenderFns }