<template>
  <comprehensive>
    <div class="ddmg-index">
      <div class="ddmg-index-one Carousel">
        <div class="Carousel_div">
          <div class="ddmg-banner">
            <el-carousel
              :interval="5000"
              arrow="never"
              autoplay
              style="width: 100%; height: 372px; border-radius: 8px"
            >
              <el-carousel-item
                v-for="(item, index) in indexBanner"
                :key="index"
                style="width: 100%; height: 372px"
              >
                <img
                  :src="item.image"
                  style="width: 100%; height: 372px; cursor: pointer"
                  @click="bannerClick(item)"
                  alt="大大买钢"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="Carousel_right">
            <div class="Carousel_rightTop" v-if="userInfo==null">
              <div class="right_Top">
                <img
                  src="./img/userImg.png"
                  alt="大大买钢"
                  style="width: 38px; height: 38px; margin-right: 8px"
                />
                <span style="color: #999999; font-size: 13px"
                  >您好，大大买钢欢迎您</span
                >
              </div>
              <div class="right_bottom">
                <button class="right_bottomBtn" @click="login">登录</button>
                <button class="right_btnZhuCe" @click="JumpCenter(0)">
                  注册
                </button>
              </div>
            </div>
            <div class="Carousel_rightTop" v-if="userInfo!=null">
              <div class="right_Top">
                <img
                  src="./img/userImg2.png"
                  alt="大大买钢"
                  style="width: 38px; height: 38px; margin-right: 8px"
                />
                <span style="font-weight: bold">{{
                  userInfo.company_name
                }}</span>
              </div>
              <div class="right_bottom">
                <button class="right_bottomBtn" @click="JumpCenter(1)">
                  买家中心
                </button>
                <button
                  @click="JumpCenter(2)"
                  class="right_bottomBtn"
                  style="background-color: #333333; color: #fff16e"
                >
                  卖家中心
                </button>
              </div>
            </div>
            <div class="ddmg-index-login">
              <div class="index-login-box">
                <div class="box-buyers-seller">
                  <div class="login-buyers-seller">
                    <div
                      :class="{
                        'box-buyers-title': true,
                        active: isBOS == false,
                      }"
                      @click="isBOS = false"
                      style="cursor: pointer"
                    >
                      <p>我是买家<span></span></p>
                    </div>
                    <div
                      :class="{
                        'box-seller-title': true,
                        active: isBOS == true,
                      }"
                      @click="isBOS = true"
                      style="cursor: pointer"
                    >
                      <p>我是卖家<span></span></p>
                    </div>
                  </div>

                  <div class="buyers-seller-box" v-if="isBOS == false">
                    <div
                      class="box-buyers-order"
                      style="cursor: pointer"
                      @click="JumpCenter(3)"
                    >
                      <div class="order-img">
                        <img src="../../assets/indeximg/4213.svg"  alt="大大买钢" />
                      </div>
                      <div>
                        <p>我的订单</p>
                      </div>
                    </div>
                    <div
                      class="box-seller-order"
                      style="cursor: pointer"
                      @click="JumpCenter(4)"
                    >
                      <div class="order-img">
                        <img src="../../assets/indeximg/4214.svg"  alt="大大买钢" />
                      </div>
                      <div>
                        <p>自助下单</p>
                      </div>
                    </div>
                  </div>

                  <div class="buyers-seller-box" v-if="isBOS == true">
<!--                    <div-->
<!--                      class="box-buyers-order"-->
<!--                      style="cursor: pointer"-->
<!--                      @click="JumpInside(4)"-->
<!--                    >-->
<!--                      <div class="order-img">-->
<!--                        <img src="../../assets/indeximg/3492.svg" alt="大大买钢" />-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <p>集采专场</p>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="box-seller-order" @click="JumpInside(5)">
                      <div class="order-img" style="cursor: pointer">
                        <img src="../../assets/indeximg/3493.svg" alt="大大买钢" />
                      </div>
                      <div>
                        <p>竞价专场</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 260px;
                    margin-top: 16px;
                    margin: 0 auto;
                    height: 1px;
                    background-image: linear-gradient(
                      37.28deg,
                      rgba(255, 255, 255, 0) 0%,
                      #dddddd 53.97%,
                      rgba(255, 255, 255, 0) 100%
                    );
                  "
                ></div>
                <div
                  style="
                    margin: 11px auto;
                    font-weight: 700;
                    color: #333333;
                    font-size: 14px;
                    text-align: center;
                  "
                >
                  实时成交
                </div>
                <div class="login-box-scoll">
                  <scroll
                    class="seamless-warp"
                    :data="transactionOrderList"
                    :class-option="classOption"
                  >
                    <ul>
                      <li
                        v-for="(item, index) in transactionOrderList"
                        :key="index"
                      >
                        <div class="right-item-title">
                          <p>{{ item.company }}</p>
                        </div>
                        <div class="right-item-kg">
                          <p>{{ item.sale }}吨</p>
                        </div>
                        <div class="right-item-time">
                          <p>{{ item.rq_ }}</p>
                        </div>
                      </li>
                    </ul>
                  </scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ddmg-index-two">
        <twoEcharts />
        <!--  走势图 -->
        <newPriceGuidance />
        <resources />
        <recommend />
        <engineering />
        <platformAndCooperation />
      </div>
    </div>
  </comprehensive>
</template>

<script>
import util from "@/libs/util.js";
import comprehensive from "../../components/comprehensive.vue";
import twoEcharts from "./components/twoEcharts.vue";
import newPriceGuidance from "./components/newPriceGuidance.vue";
import resources from "./components/resources.vue";
import recommend from "./components/recommend.vue";
import engineering from "./components/engineering.vue";
import platformAndCooperation from "./components/platformAndCooperation.vue";
import scroll from "vue-seamless-scroll";
import { DecodeBase64 } from "@/utils/encryption";
import { mapState, mapActions } from "vuex";
export default {
  name: "home",
  components: {
    comprehensive,
    scroll,
    newPriceGuidance,
    resources,
    recommend,
    engineering,
    twoEcharts,
    platformAndCooperation,
  },
  data() {
    return {
      isBOS: false,
      animate: false,
      activeName: "",
      indexBanner: [
        // { url:'https://ddmallimg.ddmg.com/public/assets/img/index/index/Carousel10.png' },
        // { url:'https://ddmallimg.ddmg.com/public/assets/img/index/index/Carousel14.png' },
        // { url:'https://ddmallimg.ddmg.com/public/assets/img/index/index/Carousel11.png' },
        // { url:'https://ddmallimg.ddmg.com/public/assets/img/index/index/Carousel12.png' },
      ],
    };
  },
  methods: {
    ...mapActions("ddmg/home", ["getTransactionOrder","getBroadcastMap"]),
    bannerClick(item) {
      if (item.bannerLink) {
          window.open(item.bannerLink, "_target");
        // this.$router.push({ name: "shop", query: { id: 722 } });
      }
    },
    login() {
      let isToken = this.isToken();
    },
  },
  computed: {
    ...mapState("ddmg/home", {
      transactionOrderList: (state) => state.transactionOrderList,
      userInfo: (state) => state.userInfo,
    }),
    classOption() {
      return {
        direction: 1,
        hoverStop: true,
        step: 0.2,
      };
    },
  },

  mounted() {
    this.getBroadcastMap().then(obj=>{
        this.indexBanner =  obj.resultdata
    });
    this.getTransactionOrder();
  },
};
</script>

<style lang="scss" scoped>
.right_btnZhuCe {
  width: 114px;
  height: 32px;
  border-radius: 16px;
  margin: 0px 12px;
  font-size: 14px;
  background-color: transparent;
  color: #ef2147;
  border: 1px solid #ef2147;
  outline: none;
  cursor: pointer;
}
.right_bottomBtn {
  width: 114px;
  height: 32px;
  background-color: #ef2147;
  border-radius: 16px;
  margin: 0px 12px;
  color: #ffffff;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
}
.Carousel_right {
  width: 307px;
  height: 372px;
  margin-left: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .right_bottom {
    width: 300px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Carousel_rightTop {
    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/dzImg.png)
      no-repeat center;
    background-size: 100%;
    width: 100%;
    height: 105px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .right_Top {
      width: 300px;
      height: 40px;
      margin-bottom: 8px;
      color: #202020;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.ddmg-banner {
  margin: 0px auto;
  height: 372px;
  width: 889px;
  border-radius: 8px;
}
.Carousel_div {
  width: 1200px;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.Carousel {
  width: 100%;
  height: 380px;
  position: relative;
  background: url(./img/CarouselBan.png) no-repeat center;
  padding-top: 16px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 360px;
  margin: 0;
}

.marquee_top {
  transition: all 0.5s;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.ddmg-index-one {
  position: relative;
}
.ddmg-index-login {
  width: 100%;
  z-index: 4;
}

.ddmg-index-login .index-login-box {
  width: 100%;
  height: 258px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.ddmg-index-login .login-box-title {
  margin-top: 12px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.ddmg-index-login .login-box-title img {
  width: 38px;
  height: 38px;
  margin-right: 7px;
}

.ddmg-index-login .login-box-title p {
  font-size: 13px;
  color: #999999ff;
  font-weight: 400;
  caret-color: rgba(0, 0, 0, 0);
  cursor: default;
}

.ddmg-index-login .login-box-button {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  border-bottom: 1px dashed #ddddddff;
  padding-bottom: 16px;
}

.ddmg-index-login .login-box-button button {
  width: 138px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #ef2147ff;
  border-radius: 2px;
  background-color: #fff;
  color: #ef2147ff;
}

.ddmg-index-login .login-box-button .active button {
  background-color: #ef2147ff;
  color: #fff;
}

.ddmg-index-login .login-buyers-seller {
  display: flex;
  justify-content: space-around;
  width: 100%;
  cursor: pointer;
}
.ddmg-index-login .buyers-seller-box {
  display: flex;
  justify-content: space-around;
}
.ddmg-index-login .box-buyers-seller {
  padding-bottom: 20px;
  width: 260px;
  padding-top: 16px;
  margin: 0 auto;
}

.ddmg-index-login .box-buyers-seller .box-buyers-title p,
.ddmg-index-login .login-box-seller .box-seller-title p {
  margin-bottom: 20px;
  height: 20px;
  width: 56px;
  text-align: center;
}

.ddmg-index-login .box-buyers-seller .active p {
  color: #ef2147ff;
}

.ddmg-index-login .box-buyers-seller .active span {
  border: 1px solid #ef2147ff;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  background-color: #ef2147ff;
}

.ddmg-index-login .box-buyers-seller img {
  width: 24px;
}

.ddmg-index-login .login-box-buyers,
.ddmg-index-login .login-box-seller {
  text-align: center;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.ddmg-index-login .box-buyers-seller .box-buyers-title,
.ddmg-index-login .box-buyers-seller .box-seller-title {
  font-size: 13px;
  font-weight: 400;
  width: 56px;
  text-align: center;
  color: #999999ff;
}

.ddmg-index-login .box-buyers-seller .box-buyers-order,
.ddmg-index-login .box-buyers-seller .box-seller-order {
  font-size: 14px;
  font-weight: 400;
  color: #666666ff;
}

.ddmg-index-login .login-box-scoll {
  font-size: 13px;
  font-weight: 400;
  margin-left: 8px;
  overflow: hidden;
  cursor: pointer;
  /*! margin: ; */
}
.ddmg-index-login .login-box-scoll ul {
  display: flex;
  flex-direction: column;
}
.ddmg-index-login .login-box-scoll ul li {
  display: flex;
  color: #333333ff;
  margin-top: 13px;
}
.ddmg-index-login .login-box-scoll ul li div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ddmg-index-login .login-box-scoll ul li .right-item-title {
  width: 111px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.ddmg-index-login .login-box-scoll ul li .right-item-title p {
  width: 123px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.ddmg-index-login .login-box-scoll ul li .right-item-kg {
  width: 65px;
  margin-left: 18px;
  font-size: 14px;
  color: #3d7dffff;
  display: flex;
  align-items: center;
}
.ddmg-index-login .login-box-scoll ul li .right-item-kg p {
  width: 52px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.ddmg-index-login .login-box-scoll ul li .right-item-kg span {
  margin-left: 1px;
  width: 12px;
}

.ddmg-index-login .login-box-scoll ul li .right-item-time {
  width: 89px;
  margin-left: 17px;
}
.ddmg-index-login .box-buyers-seller .order-img {
  width: 56px;
  height: 29px;
  /*! line-height: 53px; */
  /*! border: 1px solid #ddddddff; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.ddmg-index-login .box-buyers-order p,
.ddmg-index-login .box-seller-order p {
  margin-top: 5px;
}
.ddmg-index-two {
  background-color: #f3f5f9ff;
  padding-top: 3px;
}
</style>
