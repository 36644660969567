<template>
  <!--价格指导-->
  <div
    class="index-price-guidance"
    v-loading="pageLoading"
  >
    <div class="price-guidance-nav">
      <div class="price-guidance-title">
        <img
          src="../../../assets/indeximg/3072.svg"
          alt="大大买钢"
        />
        <p>城市报价</p>
      </div>
      <div class="price-guidance-tab">
        <div
          :class="{
            'guidance-tab-item': true,
             active: priceGuidanceTab == index,
          }"
          v-for="(item, index) in PMList"
          :key="index"
          @click="tabPM(item, index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="price-guidance-mark">
        <p>更新时间：{{ dateTime }}</p>
        <p>当前选择地：</p>
        <p>{{ cityName }}</p>
      </div>
    </div>
    <!--价格指导内容列表-->
    <!-- 代表规格 -->
    <div
      class="container-wrapper"
      v-loading='guidanceLoading'
    >
      <!-- <div
        class="item-title"
        v-if="lwCityPriceList.length >0"
      >
        <div class="item-title-center">
          代表规格
          <div style="display: flex; flex-direction: row;justify-content: center;align-items: center;">
            <div style="height:5px; width:90px; opacity: 0.3; background-color: #d8a55e;margin-top: -8px;"></div>
          </div>
        </div>

      </div> -->
      <div style="display: flex; flex-direction: row;justify-content: space-between;align-items: flex-start;padding: 20px 30px 10px 30px;">
        <div
          class="item-one"
          v-if="lwCityPriceList.length >0"
        >
          <div
            style="display: flex;width: 100%;flex-direction: row;justify-content: space-between;align-items: center;background-image: linear-gradient(180deg, #FEF7F0 0%, rgba(255,255,255,0.39) 100%);border-radius: 4px 4px 0px 0px;"
          >
            <div style="line-height: 40px;width: 100%; border-bottom: 1px solid #f1f1f1ff;">
              <img
                style="width: 20px;height: 20px;margin-left: 18px; margin-right: 12px;margin-bottom: -4px;"
                src="../img/price_one.svg"
              /><span style="color:#D8A55E;font-size: 16px; font-weight: 600;margin-right: 5px;">螺纹钢 </span> <span
                style="color:#1C1C1C;font-size: 16px; font-weight: 600;margin-right: 5px;"
              >
                HRB400E 18*9 {{ ' ' }}</span>
            </div>
          </div>
          <div style="width: 100%;height: 200px; overflow-y: auto;">
            <div
              class="wrapper-list"
              v-for="(itemLw,indexLw) in lwCityPriceList"
              :key="indexLw"
            >
              <div
                class="item-row"
                @click="$event => dayE(itemLw)"
              >
                <div class="item-left">{{ itemLw.origin }}</div>
                <div class="item-center">{{ '￥' + itemLw.thisDayPrice }}</div>
                <div
                  class="item-right"
                  v-show="itemLw.comparedWithYesterdayPrice  > 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#E42828;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">+{{Math.abs(itemLw.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemLw.comparedWithYesterdayPrice  == 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="color:#888888;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">{{ '持平' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemLw.comparedWithYesterdayPrice  < 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#19C49C;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">-{{Math.abs(itemLw.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemLw.comparedWithYesterdayPrice === null"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">--
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item-one"
          v-if="plCityPriceList.length >0"
        >
          <div
            style="display: flex;flex-direction: row;width: 100%;justify-content: space-between;align-items: flex-start;background-image: linear-gradient(180deg, #FEF7F0 0%, rgba(255,255,255,0.39) 100%);border-radius: 4px 4px 0px 0px;"
          >
            <div style="line-height: 40px;width: 100%; border-bottom: 1px solid #f1f1f1ff;">
              <img
                style="width: 20px;height: 20px;margin-left: 18px; margin-right: 12px;margin-bottom: -4px;"
                src="../img/price_two.svg"
              /><span style="color:#D8A55E;font-size: 16px; font-weight: 600;margin-right: 5px;">盘螺 </span> <span
                style="color:#1C1C1C;font-size: 16px; font-weight: 600;margin-right: 5px;"
              >
                HRB400E Φ8 {{ ' ' }}</span>
            </div>
          </div>
          <div style="width: 100%;height: 200px; overflow-y: auto;">
            <div
              class="wrapper-list"
              v-for="(itemPl,indexPl) in plCityPriceList"
              :key="indexPl"
            >
              <div
                class="item-row"
                @click="$event => dayE(itemPl)"
              >
                <div class="item-left">{{ itemPl.origin }}</div>
                <div class="item-center">{{ '￥' + itemPl.thisDayPrice }}</div>
                <div
                  class="item-right"
                  v-show="itemPl.comparedWithYesterdayPrice  > 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#E42828;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">+{{Math.abs(itemPl.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemPl.comparedWithYesterdayPrice  == 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="color:#888888;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">{{ '持平' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemPl.comparedWithYesterdayPrice  < 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#19C49C;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">-{{Math.abs(itemPl.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemPl.comparedWithYesterdayPrice === null"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">--
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item-one"
          v-if="xcCityPriceList.length >0"
        >
          <div
            style="display: flex;flex-direction: row;width: 100%;justify-content: space-between;align-items: flex-start;background-image: linear-gradient(180deg, #FEF7F0 0%, rgba(255,255,255,0.39) 100%);border-radius: 4px 4px 0px 0px;"
          >
            <div style="line-height: 40px;width: 100%; border-bottom: 1px solid #f1f1f1ff;">
              <img
                style="width: 20px;height: 20px;margin-left: 18px; margin-right: 12px;margin-bottom: -4px;"
                src="../img/price_three.svg"
              /><span style="color:#D8A55E;font-size: 16px; font-weight: 600;margin-right: 5px;">线材 </span> <span
                style="color:#1C1C1C;font-size: 16px; font-weight: 600;margin-right: 5px;"
              >
                HPB300 Φ8 {{ ' ' }}</span>
            </div>
          </div>
          <div style="width: 100%;height: 200px; overflow-y: auto;">
            <div
              class="wrapper-list"
              v-for="(itemXc,indexXc) in xcCityPriceList"
              :key="indexXc"
            >
              <div
                class="item-row"
                @click="$event => dayE(itemXc)"
              >
                <div class="item-left">{{ itemXc.origin }}</div>
                <div class="item-center">{{ '￥' + itemXc.thisDayPrice }}</div>
                <div
                  class="item-right"
                  v-show="itemXc.comparedWithYesterdayPrice  > 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#E42828;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">+{{Math.abs(itemXc.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemXc.comparedWithYesterdayPrice  == 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="color:#888888;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">{{ '持平' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemXc.comparedWithYesterdayPrice  < 0"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span
                    style="color:#19C49C;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">-{{Math.abs(itemXc.comparedWithYesterdayPrice*1) || '' }}
                  </span>
                </div>
                <div
                  class="item-right"
                  v-show="itemXc.comparedWithYesterdayPrice === null"
                ><span style="color: #7E8085; padding-right: 10px;">{{ '较昨日' }}</span>
                  <span style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all;">--
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="price-guidance-divImg"
        v-if="lwCityPriceList.length == 0 && plCityPriceList.length == 0 && xcCityPriceList.length ==0"
      >
        <img
          src="../img/nodata.gif"
          style="width: 200px; height: 200px"
          alt="大大买钢"
        />
        <div style="margin-top: 30px; font-size: 16px; color: #999999">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from 'dayjs'
export default {
  name: "pricrGuidance",
  data () {
    return {
      dataList: {},
      pageLoading: true,
      lwCityPriceList: [],  // 城市螺纹钢价格 
      plCityPriceList: [],  // 城市盘螺价格
      xcCityPriceList: [],  // 城市线材价格	
      category: "建材",
      pagination: {},
      priceGuidanceTab: 0,
      dateTime: '',
      originBasePriceList: {},
      PMList: ["建材"],
    };
  },
  mounted () {
    this.getPriceTrendsList();
  },
  methods: {
    ...mapActions("ddmg/home", ["priceTrendsList"]),
    dayE (item) {
      this.$router.push({
        name: "info", query: {
          price: item.thisDayPrice,
          origin_place: item.origin,
          product_name: item.product,
          material: item.material || '',
          specifications: item.specifications || '',
          date: dayjs(this.dateTime).format('MM-DD'),
          city: item.cityName,
          home_diff_price: item.comparedWithYesterdayPrice,
        }
      });
    },
    async getPriceTrendsList () {
      this.dataList = [];
      this.dateTime = '';
      this.lwCityPriceList = [];
      this.plCityPriceList = [];
      this.xcCityPriceList = [];
      let data = {
        cityName: this.cityName,
        isNew: 1,
        product: '螺纹钢'
        // categoryCode: this.priceGuidanceTab + 1,
      };
      this.pageLoading = true;
      const resLw =  this.priceTrendsList(data).then((res) => { return res});
      data.product = '盘螺';
      const resPl =  this.priceTrendsList(data).then((res) => { return res});
      data.product = '线材';
      const resXc =  this.priceTrendsList(data).then((res) => { return res});
      await Promise.all([resLw, resPl, resXc]).then(res => {
        this.dateTime = res &&  !!res[0].resultdata.dataTimeList && !!res[0].resultdata.dataTimeList.dataTime ? dayjs(res[0].resultdata.dataTimeList.dataTime).format('YYYY-MM-DD') : '';
        // 螺纹钢价格 
        this.lwCityPriceList = res && !!res[0].resultdata && res[0].resultdata.cityPriceList ? res[0].resultdata.cityPriceList : [];
        // 盘螺价格
        this.plCityPriceList = res && !!res[1].resultdata && res[1].resultdata.cityPriceList ? res[1].resultdata.cityPriceList : [];
        // 线材价格	
        this.xcCityPriceList = res && !!res[2].resultdata && res[2].resultdata.cityPriceList ? res[2].resultdata.cityPriceList : [];
        this.pageLoading = false;
      });
      // this.priceTrendsList(data).then((res) => {
      //   this.dateTime = !!res.resultdata.dataTimeList && !!res.resultdata.dataTimeList.dataTime ? dayjs(res.resultdata.dataTimeList.dataTime).format('YYYY-MM-DD') : '';
      //   let { lwCityPriceList, plCityPriceList, xcCityPriceList } = res.resultdata;
      //   this.lwCityPriceList = lwCityPriceList;
      //   this.plCityPriceList = plCityPriceList;
      //   this.xcCityPriceList = xcCityPriceList;
      //   this.pageLoading = false;
      // });
    },
    tabPM (item, index) {
      // this.priceGuidanceTab = index;
      this.getPriceTrendsList();

    },
    async changeCity (curVal, oldVal) {
      this.dataList = [];
      this.dateTime = '';
      this.lwCityPriceList = [];
      this.plCityPriceList = [];
      this.xcCityPriceList = [];
      let data = {
        cityName: curVal,
        isNew: 1,
        product: '螺纹钢'
      };
      this.pageLoading = true;
      const resLw =  this.priceTrendsList(data).then((res) => { return res});
      data.product = '盘螺';
      const resPl =  this.priceTrendsList(data).then((res) => { return res});
      data.product = '线材';
      const resXc =  this.priceTrendsList(data).then((res) => { return res});
      await Promise.all([resLw, resPl, resXc]).then(res => {
        this.dateTime = res &&  !!res[0].resultdata.dataTimeList && !!res[0].resultdata.dataTimeList.dataTime ? dayjs(res[0].resultdata.dataTimeList.dataTime).format('YYYY-MM-DD') : '';
        // 螺纹钢价格 
        this.lwCityPriceList = res && !!res[0].resultdata && res[0].resultdata.cityPriceList ? res[0].resultdata.cityPriceList : [];
        // 盘螺价格
        this.plCityPriceList = res && !!res[1].resultdata && res[1].resultdata.cityPriceList ? res[1].resultdata.cityPriceList : [];
        // 线材价格	
        this.xcCityPriceList = res && !!res[2].resultdata && res[2].resultdata.cityPriceList ? res[2].resultdata.cityPriceList : [];
        this.pageLoading = false;
      });
      // this.pageLoading = true;
      // this.priceTrendsList(data).then((res) => {
      //   this.pageLoading = false;
      //   this.dateTime = !!res.resultdata.dataTimeList && !!res.resultdata.dataTimeList.dataTime ? dayjs(res.resultdata.dataTimeList.dataTime).format('YYYY-MM-DD') : '';
      //   let { lwCityPriceList, plCityPriceList, xcCityPriceList } = res.resultdata;
      //   this.lwCityPriceList = lwCityPriceList;
      //   this.plCityPriceList = plCityPriceList;
      //   this.xcCityPriceList = xcCityPriceList;
      // })
      // this.priceTrendsList(data).then((res) => {
      //   this.dataList = res.resultdata;
      //   if (res.resultdata.originBasePriceList) {
      //     this.dateTime = dayjs(res.resultdata.originBasePriceList.dateTime).format('YYYY-MM-DD')
      //     this.originBasePriceList = res.resultdata.originBasePriceList
      //   }

      // });
    },
  },
  computed: {
    ...mapState("ddmg/home", {
      cityName: (state) => state.cityName,
      cityPriceTrendsNewList: (state) => state.cityPriceTrendsNewList,
      guidanceLoading: (state) => state.guidanceLoading,
    }),
  },
  watch: {
    cityName: "changeCity",
  },
};
</script>

<style lang="scss" >
.price-guidance-divImg {
  padding-bottom: 30px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.price-guidance-listdiv {
  width: 1038px;
  height: 62px;
  border-bottom: 1px dashed #ebe8e8;
  line-height: 62px;
  display: flex;
  align-items: center;
}
.price-guidance-listdiv:last-child {
  border-bottom: none;
}
.listDivHover {
  padding: 0px 15px;
}
.listDivHover:hover {
  display: inline-block;

  height: 41px;
  line-height: 41px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.12);
}
.topDivText {
  font-weight: 900;
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 5px auto;
}
.topDivSpan {
  font-weight: 900;
  color: #333333;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  text-align: center;
}
.price-guidance-Topdiv {
  width: 1108px;
  height: 115px;
  margin: 15px auto;
  background: url("../img/jgzdImg.png") no-repeat center;
  background-size: 100%;
}
.price-guidance-div {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.disable {
  pointer-events: none;
}
.pointer {
  cursor: pointer;
}
.opacity {
  opacity: 0.3;
}
.item-content-start {
  width: 70px;
  margin-right: 5px;
}
.index-price-guidance {
  width: 1200px;
  background-color: #fff;
  margin: 10px auto;
  position: relative;
}

.index-price-guidance .price-guidance-nav {
  display: flex;
  width: 1148px;
  height: 73px;
  border-bottom: 1px solid #e9e9e9ff;
  align-items: center;
  margin: 0 26px;
  box-sizing: border-box;
}

.index-price-guidance .price-guidance-nav .price-guidance-title {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.guidance-list-tab-left img {
  transform: rotate(180deg);
}
.guidance-list-tab-left,
.guidance-list-tab-right {
  display: flex;
}

.price-guidance-list-box {
  transition: all 0.5s ease;
}
.index-price-guidance .price-guidance-nav .price-guidance-title img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.index-price-guidance .price-guidance-nav .price-guidance-title p {
  font-size: 24px;
  font-weight: 700;
  color: #333333ff;
  cursor: default;
  caret-color: rgba(0, 0, 0, 0);
}

.index-price-guidance .price-guidance-nav .price-guidance-tab {
  display: flex;
  width: 660px;
}

.index-price-guidance
  .price-guidance-nav
  .price-guidance-tab
  .guidance-tab-item {
  margin-left: 30px;
  font-size: 14px;
  color: #333333ff;
  font-weight: 400;
  height: 73px;
  display: flex;
  align-items: center;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.index-price-guidance
  .price-guidance-nav
  .price-guidance-tab
  .guidance-tab-item.active {
  color: #ef2147ff;
  border-bottom: 1px solid #ef2147ff;
}

.index-price-guidance .price-guidance-nav .price-guidance-mark {
  width: 294px;
  display: flex;
  font-size: 14px;
  color: #999999ff;
  caret-color: rgba(0, 0, 0, 0);
  cursor: default;
}
.index-price-guidance .price-guidance-nav .price-guidance-mark p:nth-child(1) {
  margin-right: 20px;
}
.price-guidance-list {
  padding-bottom: 32px;
}
// .index-price-guidance .price-guidance-list .guidance-list-title {
//   height: 55px;
//   line-height: 55px;
//   font-size: 18px;
//   color: #3d7dffff;
//   font-weight: 400;
//   margin: 15px 0 15px 62px;
//   caret-color: rgba(0, 0, 0, 0);
//   cursor: default;
// }
.index-price-guidance .price-guidance-list .guidance-list-title {
  display: flex;
  justify-content: center;
  line-height: 47px;
  margin: 15px 0 15px 62px;
  cursor: default;
  align-items: center;
}
.index-price-guidance
  .price-guidance-list
  .guidance-list-title
  .guidance-list-tab-middle {
  display: flex;
  width: 400px;
  height: 47px;
  line-height: 47px;
  background-color: #fff5f5ff;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  font-size: 18px;
  justify-content: space-between;
  margin: 0 32px;
}
.index-price-guidance
  .price-guidance-list
  .guidance-list-title
  .guidance-list-tab-middle
  p:nth-child(1) {
  margin-left: 20px;
}
.index-price-guidance
  .price-guidance-list
  .guidance-list-title
  .guidance-list-tab-middle
  p:nth-child(2) {
  color: #ef2147ff;
  margin-right: 11px;
}
.index-price-guidance .price-guidance-list .guidance-list-item {
  margin-left: 40px;
  padding-top: 40px;
  display: flex;
}
.index-price-guidance .price-guidance-list div:nth-child(1) {
  padding-top: 1px;
}
.index-price-guidance .price-guidance-list .list-item-title {
  height: 47px;
  border-bottom: 1px solid #edededff;
  width: 1120px;
  border-radius: 5px;
  color: #333333ff;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px !important;
}
.index-price-guidance
  .price-guidance-list
  .list-item-title
  .list-item-title-xj {
  display: flex;
  align-items: center;
}
.index-price-guidance
  .price-guidance-list
  .list-item-title
  .list-item-title-more {
  display: flex;
  align-items: center;
}
.index-price-guidance .price-guidance-list .list-item-title img {
  margin-right: 4px;
}
.index-price-guidance .price-guidance-list .list-item-title p {
  cursor: default;
  caret-color: rgba(0, 0, 0, 0);
}

.index-price-guidance
  .price-guidance-list
  .list-item-title
  .list-item-title-more {
  display: flex;
  margin-right: 14px;
  align-items: center;
  font-size: 14px;
  color: #999999ff;
  font-weight: 400;
  cursor: pointer;
  caret-color: rgba(0, 0, 0, 0);
}

.index-price-guidance
  .price-guidance-list
  .list-item-title
  .list-item-title-more
  img {
  width: 6px;
  height: 9px;
  margin-left: 4px;
}

.index-price-guidance .price-guidance-list .list-item-body {
  display: flex;
  flex-wrap: wrap;
  width: 1120px;
}

.index-price-guidance .price-guidance-list .body-item-content {
  width: 244px;
  display: flex;
  margin-top: 30px;
  margin-right: 35px;
  font-size: 18px;
  color: #333333ff;
  font-weight: 400;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  height: 47px;
  align-items: center;
}
.index-price-guidance .price-guidance-list .item-content-now {
  width: 80px;
  margin-right: 5px;
  margin-left: 10px;
}
.index-price-guidance .price-guidance-list .item-content-now p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.index-price-guidance .price-guidance-list .item-body-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// .index-price-guidance .list-item-body div:nth-child(4n) .border-left {
//   border: none;
// }

.index-price-guidance .price-guidance-list .item-content-add {
  display: flex;
  align-items: center;
  width: 55px;
}

.index-price-guidance .content-add-img {
  margin: -4px 4px 0 1px;
  display: flex;
  align-items: center;
}
.index-price-guidance .container-wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(216, 216, 233, 0.5);
  border-radius: 4px;
  padding-bottom: 10px;
}
.index-price-guidance .container-wrapper .item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 30px;
  height: 46px;
  font-size: 18px;
  color: #d8a55e;
  font-weight: 600;
  background-color: #ffffff;
  text-align: center;
}
.item-title-center {
  width: 100%;
  text-align: center;
}
.item-one {
  width: 31%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 7px 0px rgba(216, 216, 233, 1);
}
.wrapper-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wrapper-list .item-row {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  // padding-right: 20px;
  font-size: 16px;
  color: #1c1c1c;
  line-height: 32px;
}
.item-left {
  font-family: PingFang SC;
  font-size: 16px;
  color: #1c1c1c;
  flex: 1;
}
.item-center {
  font-family: PingFang SC;
  font-size: 16px;
  color: #1c1c1c;
  flex: 1;
}
.item-right {
  flex: 1;
  font-size: 13px;
  color: #474850;
  text-align: left;
  // margin-right: 10px;
}
</style>
