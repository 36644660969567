var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.companyLoading),expression:"companyLoading"}],staticClass:"index-recommend"},[_c('div',{staticClass:"recommend-nav"},[_vm._m(0),_c('div',{staticClass:"recommend-tab"},_vm._l((_vm.regionList),function(item,index){return _c('div',{key:index,class:{
          'recommend-item': true,
          active: _vm.recommendTab == index,
        },on:{"click":function($event){return _vm.tabRegion(index, item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"recommend-mark",on:{"click":function($event){return _vm.JumpInside(1)}}},[_c('p',[_vm._v("品质商铺，尽在其中")]),_c('img',{attrs:{"src":require("../../../assets/indeximg/menu-right.png"),"alt":"大大买钢"}})])]),(_vm.companyList.length >0)?_c('div',{staticClass:"index-recommend-list"},_vm._l((_vm.companyList),function(item,index){return _c('div',{key:index,staticClass:"recommend-list-item item-border"},[_c('div',{class:item.isInternalCompany != 1
            ? 'recommend-item-title item-title-blue'
            : 'recommend-item-title item-title-yellow'},[_c('div',{staticClass:"item-title-img"}),_c('div',{staticClass:"recommend-item-desc",on:{"click":function($event){return _vm.JumpInside(3, item.companyId)}}},[_c('div',{staticClass:"recommend-desc-name"},[_c('p',[_vm._v(_vm._s(item.companyName))])]),_c('div',{staticClass:"recommend-desc-situation"},[_c('p',[_vm._v("近3月 成交"+_vm._s(item.lastThreeMonthTrading)+"万吨")])]),_c('div',{staticClass:"recommend-desc-label"},[(item.isInternalCompany == 1)?_c('p',[_vm._v("直营店铺")]):_vm._e(),_c('p',[_vm._v("一票制/多票制")])])])]),_c('div',{staticClass:"recommend-item-body"},_vm._l((item.goods),function(xitem,xindex){return _c('div',{key:xindex,staticClass:"recommend-item-strip",on:{"click":function($event){return _vm.JumpInside(2,xitem.id)}}},[_c('div',{staticClass:"recommend-strip-left"},[_c('div',{staticClass:"strip-left-title"},[_c('p',[_vm._v(_vm._s(xitem.originPlace)+" "+_vm._s(xitem.productName))])]),_c('div',{staticClass:"strip-left-desc"},[_c('p',[_vm._v(_vm._s(xitem.material)+" "+_vm._s(xitem.specifications))])])]),_c('div',{staticClass:"recommend-strip-right"},[_c('div',{staticClass:"strip-left-price"},[_c('p',[_vm._v("￥"+_vm._s(xitem.price))])]),_c('div',{staticClass:"strip-left-date"},[_c('p',[_vm._v(_vm._s(xitem.createTime))])])])])}),0)])}),0):_vm._e(),(_vm.companyList.length == 0)?_c('div',{staticClass:"price-guidance-divImg"},[_c('img',{staticStyle:{"width":"200px","height":"200px"},attrs:{"src":require("../img/nodata.gif"),"alt":"大大买钢"}}),_c('div',{staticStyle:{"margin-top":"30px","font-size":"16px","color":"#999999"}},[_vm._v(" 暂无数据 ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommend-title"},[_c('img',{attrs:{"src":require("../../../assets/indeximg/3068.svg"),"alt":"大大买钢"}}),_c('p',[_vm._v("推荐商铺")])])
}]

export { render, staticRenderFns }