<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  平台服务和合作伙伴
-->
<template>
  <div class="platcoopeer">
    <!--平台服务-->
    <div class="platform">
      <div class="platform-header">
        <div class="platform-title">
          <img src="../../../assets/indeximg/3120.svg" alt="大大买钢"/>
          <p>平台服务</p>
        </div>
        <div class="platform-desc">
          利用云计算、大数据、微服务等互联网技术，整合钢铁产业链流通环节资源，为平台用户提供系列全数字化交易、金融、物流、资讯产品与服务。
        </div>
      </div>
      <div class="platform-body">
        <div
          :class="{ 'platform-body-tiem': true }"
          v-for="(item, index) in platformList"
          :key="index"
          @mouseenter="onHover(index)"
          @mouseleave="onLeave(index)"
        >
          <div
            :class="{
              'platform-body-bg': true,
              'img-actives': item.platformListItem == index + 1,
            }"
            :style="{
              'background-image':
                'url(' +
                require('../../../assets/indeximg/Industrychain_' +
                  (index + 1) +
                  '.png') +
                ')',
            }"
            style="
              height: 182px;
              width: 274px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
            "
          ></div>
          <div

            :class="{
              'platform-body-item-img': true,
              'img-active': item.platformListItem == index + 1,
            }"
          >
            <img
              :src="
                require('../../../assets/indeximg/financial' +
                  (index + 1) +
                  '.png')
              "
              alt="大大买钢"
            />
          </div>
          <div
            class="platform-body-item-button"
            v-if="item.platformListItem == index + 1 && item.gotoHref"
            @click="gotonewPage(item.gotoHref)"
          >
            <p>查看详情</p>
          </div>
        </div>

  <div
          class="platform-body-tiem platform-body-tiem-sd"
        >

          <div  class="platform-body-item-img platform-body-item-img-hover">
            <img src="../../../assets/indeximg/financial4.png" alt="大大买钢"/>
          </div>
        </div>



      </div>
    </div>
    <!--合作伙伴-->
    <div class="cooperation">
      <div class="platform-header">
        <div class="platform-title">
          <img src="../../../assets/indeximg/3119.svg" alt="大大买钢"/>
          <p>合作伙伴</p>
        </div>
        <div class="platform-desc">我们的品牌商。</div>
      </div>
      <div class="cooperation-body">
        <!-- 添加的图片 -->
        <div>
          <ul>
            <li><img src="../../../assets/indeximg/hzhb/01.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/02.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/03.png"  alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/04.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/05.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/06.png" alt="大大买钢"></li>
          </ul>
          <ul>
            <li><img src="../../../assets/indeximg/hzhb/07.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/08.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/09.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/10.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/11.png" alt="大大买钢"></li>
            <li><img src="../../../assets/indeximg/hzhb/12.png"  alt="大大买钢"></li>
          </ul>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'

export default {
  name: "platformAndCooperation",
  components: {


  },
  data() {
    return {
      platformList: [
        { gotoHref: "supplyChain", platformListItem: 0 },
        { gotoHref: false, platformListItem: 0 },
        { gotoHref: "https://rzn.ddmg.com/", platformListItem: 0},
        // {
        // //  gotoHref: "supplyChain",
        //   wxImg: "Industrychain_9",
        //   platformListItem: 0,needBG:true
        // },
      ],
      swiperOption: {
        pagination: ".swiper-pagination1",
        slidesPerView: 6,
        slidesPerColumn: 1,
        slidesPerColumnFill: "row",
        centeredSlides: false,
        onSlideChangeEnd: (swiper) => {
          //放swiper的回调方法
          this.page = swiper.realIndex + 1;
          this.index = swiper.realIndex;
        },
        //左右导航栏
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        //循环
       loop: true,
      },
    };
  },
  methods: {
    onHover(index) {

          this.platformList[index].platformListItem = index + 1;


    },
    onLeave(index) {
      this.platformList[index].platformListItem = 0;
    },
    gotonewPage(val) {

      if (val.startsWith("http")) {
        window.open(val, "_blank");
      } else {
        this.$router.push({ name: val ,query: { cur:1},});
      }
    },
  },
  computed: {
  },
  mounted() {

  },
};
</script>

<style lang="scss" >
.platform {
  display: flex;
  margin: 10px auto;
  background-color: #ffffff;
  max-width: 1200px;
  flex-direction: column;
}
.platform-header {
  display: flex;
  justify-content: space-between;
  margin: 22px 20px 0 21px;
  padding-bottom: 20px;
  border-bottom: 2px solid #dddddd;
}
.platform-title {
  display: flex;
  align-items: center;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  font-size: 24px;
  line-height: 28px;
}
.platform-title img {
  margin-right: 11px;
}
.platform-desc {
  font-family: PingFang SC;
  color: #999999;
  font-size: 14px;
  line-height: 28px;
}
.platform-body {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}
.platform-body-tiem {
  width: 274px;
  height: 182px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 40px;
}
.img-active {
  top: 15px !important;
}
.img-actives {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.platform-body-item-img {
  position: absolute;
  height: 108px;
  width: 242px;
  top: 25%;
  transition: all 0.5s ease;
  z-index: 10;
}
.platform-body-item-button {
  height: 30px;
  border: none;
  background: #ffffff;
  outline: none;
  color: #ff6200;
  font-size: 14px;
  border-radius: 0;
  line-height: 30px;
  width: 98px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  //  animation-delay:1s;
  // -webkit-animation-delay:1s; /* Safari 和 Chrome */
  cursor: pointer;
  z-index: 11;
}
.cooperation {
  display: flex;
  margin: 10px auto;
  background-color: #ffffff;
  max-width: 1200px;
  flex-direction: column;
}
.cooperation-body {
  margin:18px
}
.cooperation-body ul{
  display: flex;
  justify-content: space-between;
}
.cooperation-body ul li{
    width: 168px;
  height: 72px;
  border:1px solid #f1f1f1;
  margin:15px 0;
}

.slider-img {
  width: 168px;
  height: 72px;
}
.platform-body-item-img-sd{

}
.platform-body-tiem-sd{
  background: url('../../../assets/indeximg/Industrychain_4.png') no-repeat;
    height: 182px;
              width: 274px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
}
.platform-body-tiem-sd:hover{
background: url('../../../assets/indeximg/Industrychain_9.png') no-repeat;
    height: 182px;
              width: 274px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
}
.platform-body-tiem-sd:hover .platform-body-item-img-hover{
    display: none;
}
.platform-body-item-img-hover:hover img{
  display: none;
}
</style>
